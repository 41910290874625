import { Suspense, useEffect } from 'react';

import './index.css';

import { usePathname } from 'next/navigation';

const animatedAttributeName = 'data-animated';
const selector = `[data-animate]:not([${animatedAttributeName}])`;

/**
 * Check if rect is in viewport
 * @param {number} rect.top
 * @returns boolean
 */
const elementIsInViewport = (element, windowInnerHeight) => {
  const rect = element.getBoundingClientRect();

  const top = rect.top - windowInnerHeight;

  return top < 0;
};

const AnimateOnScrollDynamic = () => {
  const pathname = usePathname();

  useEffect(() => {
    /**
     * Add animated class to elements in viewport
     */
    const update = () => {
      // Get all animated elements
      const animatedElements = document.querySelectorAll(selector);

      const windowInnerHeight = window.innerHeight;

      Array.from(animatedElements).forEach((element) => {
        if (elementIsInViewport(element, windowInnerHeight)) {
          element.setAttribute(animatedAttributeName, 'true');
        }
      });
    };

    update();

    window.addEventListener('scroll', update, {
      passive: true,
    });
    window.addEventListener('resize', update);

    return () => {
      window.removeEventListener('scroll', update);
      window.removeEventListener('resize', update);
    };
  }, [pathname]);
};

export const AnimateOnScroll = () => {
  return (
    <Suspense>
      <AnimateOnScrollDynamic />
    </Suspense>
  );
};
